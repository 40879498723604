<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      main_chart_of_accounts_types: [],
      MainChartOfAccountsTypes: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMainChartOfAccountsTypes: null,
      MainChartOfAccountsTypesSearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addMainChartOfAccountsTypes() {
      var data = {
        title: "popups.addUsers",
        inputs: [
          { model: "id", type: "text", label: "id" },

          { model: "name", type: "text", label: "name" },

          { model: "type", type: "text", label: "type" },

          { model: "created", type: "text", label: "created" },

          { model: "updated", type: "text", label: "updated" },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("main_chart_of_accounts_types", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.MainChartOfAccountsTypesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("main-chart-of-accounts-types/search", {
          search: this.searchMainChartOfAccountsTypes,
        })
        .then((res) => {
          this.main_chart_of_accounts_types = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchMainChartOfAccountsTypes = "";
      this.MainChartOfAccountsTypesSearchMode = false;
      this.get(this.page);
    },
    getMainChartOfAccountsTypes() {
      this.http.get("main-chart-of-accounts-types").then((res) => {
        this.main_chart_of_accounts_types = res.data;
      });
    },
    deleteMainChartOfAccountsTypes(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http
                .delete("main-chart-of-accounts-types", app.id)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    get(page) {
      console.log(page);
      this.http
        .post("main-chart-of-accounts-types/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.main_chart_of_accounts_types = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getMainChartOfAccountsTypes()
  },
};
</script>

<template>
  <Layout>
    <PageHeader title="Payment App" />
    <button
      type="button"
      @click="addMainChartOfAccountsTypes()"
      class="btn btn-light float-end mb-4"
      data-bs-toggle="modal"
      data-bs-target="#Add"
    >
      <span
        class="bx bxs-plus-circle float-end fa-2x"
        style="color: #2a3042 !important"
      ></span>
    </button>

    <table class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">id</th>
          <th scope="col">name</th>
          <th scope="col">Type</th>
          <th scope="col">created</th>
          <th scope="col">updated</th>
          <!-- <th scope="col">operations</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in main_chart_of_accounts_types"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.type.toLowerCase() == "c" ? "Credit" : "Debit" }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <!-- <td>
            <button
              type="button"
              class="btn btn-success me-2 btn-sm"
              @click="editMainChartOfAccountsTypes(app)"
            >
              {{ $t("popups.edit") }}
            </button>
            <a
              class="btn btn-danger btn-sm me-2"
              href="javascript:void(0)"
              role="button"
              @click="deleteMainChartOfAccountsTypes(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td> -->
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="video"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal video</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <video style="width: 470px; height: 360px" controls>
              <source src="/Rec 0008.mp4" type="video/mp4" />
              <source src="/Rec 0008.mp4" type="video/ogg" />
              <source src="/Rec 0008.mp4" type="video/webm" />
              <source src="/Rec 0008.mp4" type="video/x-flv" />
            </video>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <!--end-->
      <!-- Modal -->
      <div
        class="modal fade w-100"
        id="Add"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="section">
                <h1>Basic Information</h1>

                <div class="row"></div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="form-label mt-2">
                      Programming Language
                    </label>
                    <select class="form-select" v-model="c">
                      <option>Python</option>
                      <option>PHP</option>
                      <option>NodeJS</option>
                      <option>Java</option>
                    </select>
                  </div>
                  <div class="col">
                    <label class="form-label mt-2">Icon </label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <br /><!--end--><br />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Add
            </button>
            <button type="button" class="btn btn-primary">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>
